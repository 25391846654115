import { Component, ViewEncapsulation } from '@angular/core';
import { Plugins } from '@capacitor/core';

import { Platform, ToastController, AlertController } from '@ionic/angular';
const { SplashScreen, StatusBar, Browser, Device } = Plugins;

// Translation
import { TranslateService } from '@ngx-translate/core';

// Import services
import { AuthService } from './services/auth.service/auth.service';
import { SettingsService } from './services/settings.service/settings.service';

// Import models
import { User } from './classes/user';

// analytics imports
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  public appPages;
  private isLoggedIn: boolean = false;
  dark = false;

  constructor(
    private platform: Platform,
    public translate: TranslateService,
    private authService: AuthService,
    private segment: SegmentService,
    private settingsService: SettingsService,
    public toastController: ToastController,
    public alertController: AlertController,
  ) {

    // initialzin the app
    this.initializeApp();

  }

  async initializeApp() {
    await this.platform.ready();

    // setting up auth listener
    const authListener = this.authService.user$.subscribe(user => this.rebuildMenu(user));
    this.authService.loginState$.subscribe(async (msg) => {
      if (msg) {
        const toast = await this.toastController.create({
          message: msg,
          duration: 2000
        });
        toast.present();
      }
    });

    // listening to settings change to upate the dark theme
    this.settingsService.settingsState$.subscribe(async (s) => {
      this.dark = s.dark;
      this.rebuildMenu();
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    const settings = await this.settingsService.getSettings();

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(settings.locale_help);
    this.translate.get('HOME').subscribe((res: string) => { console.log(res) });

    //this.appPages = this.defaultAppPages.slice(); // copying array
    await this.rebuildMenu();

    SplashScreen.hide().catch(error => {
      console.error(error);
    });
    StatusBar.hide().catch(error => {
      console.error(error);
    });

    // analytics
    this.segment.track('App Loaded', {
      version: environment.version,
      platform: this.platform.platforms(),
    }).then(() => console.log("App Loaded event sent"));
  }

  async rebuildMenu(user?: User) {
    user = user || await this.authService.getUser();
    console.log("Building menu");
    console.log(user);

    // translating menu
    const HOME_STR = await this.translate.get("HOME").toPromise();
    const LEARN_STR = await this.translate.get("LEARN").toPromise();
    const SETTINGS_STR = await this.translate.get("SETTINGS").toPromise();
    const HELP_STR = await this.translate.get("HELP").toPromise();
    const LOGIN_STR = await this.translate.get("LOGIN").toPromise();
    const MY_PROFILE_STR = await this.translate.get("MY_PROFILE").toPromise();

    const defaultAppPages = [
      {
        title: HOME_STR,
        url: '/home',
        icon: 'home',
        forLoggedIn: true,
        forAnonymous: true,
        forNotLoggedIn: false,
      },
      {
        title: LEARN_STR,
        url: '/learn',
        icon: 'bulb',
        forLoggedIn: true,
        forAnonymous: true,
        forNotLoggedIn: false,
      },
      {
        title: SETTINGS_STR,
        url: '/settings',
        icon: 'settings',
        forLoggedIn: true,
        forAnonymous: true,
        forNotLoggedIn: false,
      },
      {
        title: HELP_STR,
        url: '/landing',
        icon: 'help',
        forLoggedIn: true,
        forAnonymous: true,
        forNotLoggedIn: true,
      },
      {
        title: LOGIN_STR,
        url: '/signin',
        icon: 'log-in',
        forLoggedIn: false,
        forAnonymous: true,
        forNotLoggedIn: true,
      },
      {
        title: MY_PROFILE_STR,
        url: '/profile',
        icon: 'person',
        forLoggedIn: true,
        forAnonymous: false,
        forNotLoggedIn: false,
      },
    ];

    // updating the pages
    this.appPages = defaultAppPages.filter(p => {
      if (user) {
        return (user.auth.isAnonymous && p.forAnonymous) || (!user.auth.isAnonymous && p.forLoggedIn);
      }
      else {
        return (p.forNotLoggedIn);
      }
    });

  }

  async openExternalUrl(url: string) {
    //this.iab.create(url);
    //window.open(url, "_blank");
    await Browser.open({ url });

    // analytics
    this.segment.track('Privacy Terms Clicked', {
      version: environment.version,
      platform: this.platform.platforms(),
    });

  }

  formatLanguageCode(deviceCode: string): string {
    return deviceCode.substring(0, 2);
  }

}
