import { LocalizedString } from './localized-string';
import { Settings } from './settings';
interface IImages {
    img_150X150: string;
    img_650X300: string;
}

export class Story {
    id: number;
    key: string;
    created: number;
    title: LocalizedString;
    description: LocalizedString;
    imgs: IImages;
    provider: string;
    link: string;
    articles: any[];
    
    constructor(params: any, settings: Settings) {
        if (params.key) this.key = <string>params.key;
        //if (params.id) this.id = <number>params.id;
        this.title = new LocalizedString(params.title, settings);
        this.description = new LocalizedString(params.description, settings);
        this.provider = params.provider;
        this.link = params.link;
        this.imgs = {
            img_150X150: (params.img_150X150?params.img_150X150:"assets/images/blank150X150.png"),
            img_650X300: (params.img_650X300?params.img_650X300:"assets/images/blank650X300.png")
        }
        if(params.articles) this.articles = Object.values(params.articles).sort((a1:any,a2:any)=>a1.order-a2.order).map((a:any)=>a.id);
        this.created = <number>params.created;
    }
}