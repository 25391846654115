
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
//import { GooglePlus } from '@ionic-native/google-plus';
//import { Facebook } from '@ionic-native/facebook';

// Import services
//import { DataService } from '../data/data.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, first, mergeMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

// Import services
import { SettingsService } from '../settings.service/settings.service';

// Import models
import { User } from '../../classes/user';

// import { GoogleAuthProvider, User, AuthCredential, FacebookAuthProvider } from '@firebase/auth-types';
// import * as firebase from 'firebase/app';

import { Plugins } from '@capacitor/core';
const { Device, CapacitorFirebaseAuth } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user$: Observable<User>;
  public user_id: string = null;

  private loginStateSource = new BehaviorSubject<string>("");
  public loginState$ = this.loginStateSource.asObservable();

  constructor(
    private platform: Platform,
    public afAuth: AngularFireAuth,
    private afDatabase: AngularFireDatabase,

    public storage: Storage,
    private settingsService: SettingsService,
  ) {
    console.log('Hello AuthService Provider');
    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          this.user_id = user.uid;
          return this.afDatabase.object(`/users/${user.uid}`).valueChanges()
            .pipe(
              map(userdata => {
                return { ...<any>userdata, auth: user };
              }));
        } else {
          // Logged out
          return of(null);
        }
      })
    )

  }

  /**
   * Updates the user data
   * @param   {any} updates the data to update
   *
   * @returns {Promise<void>} a Promise
   */
  async updateUserData(updates: any, force_uid?: string): Promise<void> {
    if (force_uid) this.user_id = force_uid; // forcing change in uid
    return await this.afDatabase.object(`/users/${this.user_id}`).update(updates);
  }

  /**
   * Returns Promise of current user
   *
   * @returns {Promise<User>} a Promise of current user
   */
  async getUser(): Promise<User> {
    let user = await this.user$.pipe(first()).toPromise();

    if (user) {
      return user;
    } else
      return null;

  }

  /**
   * Registers the user account
   * @param   {string} newEmail the new user's email
   * @param   {string} newPassword the new user's password
   *
   * @returns {Promise<auth.UserCredential>} the user
   */
  async registerUser(newEmail: string, newPassword: string): Promise<auth.UserCredential> {

    if (auth().currentUser) { // anonymous then need to link to that anynymous user
      return this.linkAccount(newEmail, newPassword);
    }

    // new registration
    let userCredential = await this.afAuth.auth.createUserWithEmailAndPassword(newEmail, newPassword);
    console.log("Register user with Password complete");
    this.loginStateSource.next("Register user with Password complete");
    console.log(userCredential);
    await this.updateUserData({
      email: newEmail,
      id: userCredential.user.uid,
      create_date: Date.now(),
      create_date_string: new Date(),
      create_method: "created",
      provider: userCredential.additionalUserInfo.providerId
    });
    return userCredential;
  }

  /**
   * Links anonynous user to user account
   * @param   {string} newEmail the new user's email
   * @param   {string} newPassword the new user's password
   *
   * @returns {Promise<auth.UserCredential>} the user
   */
  async linkAccount(email: string, password: string): Promise<auth.UserCredential> {
    const credential = auth.EmailAuthProvider.credential(email, password);
    const userCredential = await this.afAuth.auth.currentUser.linkAndRetrieveDataWithCredential(credential);
    console.log("Link Account complete");
    this.loginStateSource.next("Successfully linked Account to email");
    console.log(userCredential);
    await this.updateUserData({
      email: email,
      id: userCredential.user.uid,
      create_date: Date.now(),
      create_date_string: new Date(),
      create_method: "linked",
      provider: credential.providerId
    });
    return userCredential;
  }

  /**
   * Router for Login providers
   * @param   {string} providerName the auth provider (Google, Facebook, Email)
   * @param   {string} email the user's email
   * @param   {string} password the user's password
   *
   * @returns {Promise<auth.UserCredential>} user credentials auth
   */
  async login(providerName: string, email?: string, password?: string): Promise<firebase.auth.UserCredential> {
    switch (providerName) {
      case "Google":
        return await this.loginWithGoogle();
        break;
      case "Facebook":
        return await this.loginWithFacebook();
        break;
      case "Email":
        return await this.loginWithEmail(email, password);
        break;
    }

  }

  /**
   * Logs in the user with email and password on browser and Native
   * @param   {string} email the user's email
   * @param   {string} password the user's password
   *
   * @returns {Promise<auth.UserCredential>} user credentials auth
   */
  async loginWithEmail(email: string, password: string): Promise<firebase.auth.UserCredential> {
    let userCredential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    console.log("Log in with Password complete");
    this.loginStateSource.next("Successfully logged in with email");
    console.log(userCredential);
    // syncing the provider and the image and the displayName
    const updates = {
      provider: userCredential.additionalUserInfo.providerId,
      imageUrl: userCredential.user.providerData[0].photoURL,
      displayName: userCredential.user.providerData[0].displayName,
      email: userCredential.user.email
    };
    await this.updateUserData(updates);
    return userCredential;
  }

  /**
    * Logs in the user Anonymously on browser and Native
    *
    * @returns {Promise<auth.UserCredential>} user credentials auth
    */
  async anonymousLogin(): Promise<firebase.auth.UserCredential> {
    let userCredential = await this.afAuth.auth.signInAnonymously();
    console.log("Log in with Anonymous complete");
    this.loginStateSource.next("Anonymous log in");
    console.log(userCredential);
    await this.updateUserData({
      id: userCredential.user.uid,
      create_date: Date.now(),
      create_date_string: new Date(),
      create_method: "anonymous",
      provider: userCredential.additionalUserInfo.providerId
    }, userCredential.user.uid);
    return userCredential;
  }

  /**
 * Logs in the user with Google on Native or Browser
 *
 * @returns {Promise<auth.UserCredential>} user credentials
 */
  async loginWithGoogle(): Promise<auth.UserCredential> {
    console.log("Login with Google")

    if (this.platform.is("capacitor")) {
      return await this.nativeAuth("Google");
    } else {
      return await this.webAuth("Google");
    }
  }

  /**
   * Logs in the user with Facebook on Native or Web
   *
   * @returns {Promise<auth.UserCredential>} user credentials
   */
  async loginWithFacebook(): Promise<auth.UserCredential> {

    if (this.platform.is("capacitor")) {
      return await this.nativeAuth("Facebook");
    } else {
      return await this.webAuth("Facebook");
    }

  }

  /**
   * Logs in the user with Google or Facebook on web
   *
   * @returns {Promise<auth.UserCredential>} user credentials
   */
  async webAuth(providerName: string): Promise<auth.UserCredential> {

    let userCredential: auth.UserCredential;
    let newUserUpdates: any = {};
    const isUserAnonymous: boolean = auth().currentUser && auth().currentUser.isAnonymous;
    const isUserDirectLogin: boolean = auth().currentUser == null && !isUserAnonymous;

    // SIGN IN OR LINK IN WEB LAYER

    let providerConfigs: any = {};
    switch (providerName) {
      case "Google":
        providerConfigs = {
          provider: new auth.GoogleAuthProvider(),
          name: providerName
        }
        break;
      case "Facebook":
        providerConfigs = {
          provider: new auth.FacebookAuthProvider(),
          name: providerName
        }
        break;
    }

    // if clean login, no need to link (but can be new user)
    if (isUserDirectLogin) {
      userCredential = await this.afAuth.auth.signInWithPopup(providerConfigs.provider);
      if (userCredential.additionalUserInfo.isNewUser) {
        console.log("----NEW USER-----");
        console.log(userCredential);
        newUserUpdates = {
          create_date: Date.now(),
          create_date_string: new Date(),
          create_method: "created",
        };
        this.loginStateSource.next(`New Account created with ${providerConfigs.name}`);
      } else {
        this.loginStateSource.next(`Successfully loggedin with ${providerConfigs.name}`);
      }
    }
    // user was browsing anonymously, need to link
    else if (isUserAnonymous) {
      try { // if the anonymous user is brand new then link
        userCredential = await auth().currentUser.linkWithPopup(providerConfigs.provider);
        this.loginStateSource.next(`Successfully linked to ${providerConfigs.name} Account`);
      } catch (error) { // if anoynomous user already has an email then simply signin
        if (error.code == "auth/credential-already-in-use") {
          userCredential = await this.afAuth.auth.signInWithCredential(error.credential);
          this.loginStateSource.next(`Successfully logged in with ${providerConfigs.name}`);
        } else {
          throw error;
        }
      }
    }

    // SAVE USER DATA TO FB

    // syncing the provider and the image and the displayName
    const updates = {
      provider: userCredential.additionalUserInfo.providerId,
      imageUrl: userCredential.user.providerData[0].photoURL,
      displayName: userCredential.user.providerData[0].displayName,
      email: userCredential.user.email
    };
    await this.updateUserData({ ...updates, ...newUserUpdates }, userCredential.user.uid);

    // RETURN CREDENTIAL

    return userCredential;
  }

  /**
  * Logs in the user with Google or Facebook on Native devices
  *
  * @returns {Promise<auth.UserCredential>} user credentials
  */
  async nativeAuth(providerName: string): Promise<auth.UserCredential> {

    let userCredential: auth.UserCredential;
    let newUserUpdates: any = {};
    const isUserAnonymous: boolean = auth().currentUser && auth().currentUser.isAnonymous;
    const isUserDirectLogin: boolean = auth().currentUser == null && !isUserAnonymous;

    // SIGNIN in to NATIVE LAYER

    let providerConfigs: any = {};
    let providerId: string;
    switch (providerName) {
      case "Google":
        providerConfigs = {
          providerId: auth.GoogleAuthProvider.PROVIDER_ID,
          name: providerName
        };
        providerId = providerConfigs.providerId;;
        providerConfigs.response = await CapacitorFirebaseAuth.signIn({ providerId });
        providerConfigs.credential = auth.GoogleAuthProvider.credential(providerConfigs.response.idToken);
        break;
      case "Facebook":
        providerConfigs = {
          providerId: auth.FacebookAuthProvider.PROVIDER_ID,
          name: providerName
        }
        providerId = providerConfigs.providerId;;
        providerConfigs.response = await CapacitorFirebaseAuth.signIn({ providerId });
        providerConfigs.credential = auth.FacebookAuthProvider.credential(providerConfigs.response.idToken);
        break;
    }

    // SIGN IN OR LINK IN WEB LAYER

    // if clean login, no need to link (but can be new user)
    if (isUserDirectLogin) {
      userCredential = await this.afAuth.auth.signInWithCredential(providerConfigs.credential);
      if (userCredential.additionalUserInfo.isNewUser) {
        newUserUpdates = {
          create_date: Date.now(),
          create_date_string: new Date(),
          create_method: "created",
        };
        this.loginStateSource.next(`New Account created with ${providerConfigs.name}`);
      } else {
        this.loginStateSource.next(`Successfully loggedin with ${providerConfigs.name}`);
      }
    }

    // user was browsing anonymously, need to link
    else if (isUserAnonymous) {
      try { // if the anonymous user is brand new then link
        userCredential = await auth().currentUser.linkWithCredential(providerConfigs.credential);
        this.loginStateSource.next(`Successfully linked to ${providerConfigs.name} Account`);
      } catch (error) { // if anoynomous user already has an email then simply signin
        if (error.code == "auth/credential-already-in-use") {
          userCredential = await this.afAuth.auth.signInWithCredential(providerConfigs.credential);
          this.loginStateSource.next(`Successfully logged in with ${providerConfigs.name}`);
        } else {
          throw error;
        }
      }
    }

    // SAVE USER DATA TO FB

    // syncing the provider and the image and the displayName
    const updates = {
      provider: userCredential.additionalUserInfo.providerId,
      imageUrl: userCredential.user.providerData[0].photoURL,
      displayName: userCredential.user.providerData[0].displayName,
      email: userCredential.user.email
    };
    await this.updateUserData({ ...updates, ...newUserUpdates }, userCredential.user.uid);

    // RETURN CREDENTIAL

    return userCredential;
  }

  /**
   * Resets the user password
   * @param   {string} email the user's email
   *
   * @returns {Promise<void>} void
   */
  async resetPassword(email: string): Promise<void> {
    await this.afAuth.auth.sendPasswordResetEmail(email);
    this.loginStateSource.next("Reset Password email sent");
  }

  /**
   * Logs out the current user
   *
   * @returns {Promise<void>} void
   */
  async logoutUser(): Promise<void> {
    // clearing all app data
    // await this.storage.clear();
    // this.settingsService.clear();

    // native signout
    if (this.platform.is("capacitor")) {
      await CapacitorFirebaseAuth.signOut({});
    }

    // web signout
    await this.afAuth.auth.signOut();
    this.user_id = null;
    this.loginStateSource.next("Sign-out success");
  }

}
