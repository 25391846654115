// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  version: "3.5.202003243",
  segment: {
    writeKey: "6lJqnnLPKVSCknXZMYSjQGPeZDqj2KLt"
  },
  firebase: {
    apiKey: "AIzaSyBHMIoR7CZ1NQNa9-_vE1ihnlXAnexV1Tk",
    authDomain: "the-news-in-twos.firebaseapp.com",
    databaseURL: "https://the-news-in-twos.firebaseio.com",
    projectId: "the-news-in-twos",
    storageBucket: "the-news-in-twos.appspot.com",
    messagingSenderId: "868501094888"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
