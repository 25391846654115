import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';

// Import services
import { AuthService } from '../auth.service/auth.service';
import { SettingsService } from '../settings.service/settings.service';

import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import * as firebase from 'firebase/app';

import { Article } from '../../classes/article';
import { LocalizedString } from '../../classes/localized-string';
import { Story } from '../../classes/story';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public afDatabase: AngularFireDatabase,
    private settingsService: SettingsService,
    private authService: AuthService,
  ) {
    console.log('Hello DataProvider Provider');
  }

  /**
   * Since all data is stored at the user level which depends on user id,
   * this function dynamically fethces the user doc location
   *
   * @returns {AngularFirestoreDocument} location of the user doc
   */
  getUserDoc(): AngularFireObject<any> {
    return this.afDatabase.object(`/users/${this.authService.user_id}`);
  }

  getUserData(): Promise<any> {
    return this.getUserDoc().valueChanges().pipe(take(1)).toPromise();
  }

  updateUserData(updates: any): Promise<void> {
    const extraUpdateData = {
      lastupdate_date: Date.now(), 
      lastupdate_date_string: new Date(),
    }
    return this.getUserDoc().update({...updates,...extraUpdateData});
  }

  /**
   * Fetches latest Articles based on category
   * @param   {number} itemsPerPage Number of items to load
   * @param   {number} startAtElement? the ID of the article from where to start fetching
   * @param   {string} category? Optional category
   *
   * @returns {Promise<{data:Article[],moreToLoad:boolean}>} an object containing the articles
   */
  getArticles(itemsPerPage: number = 5, startAtElement?: number, category?: string): Promise<{ data: Article[], moreToLoad: boolean }> {

    // execute query
    return this.afDatabase.list('/articles', function (ref) {
      if (startAtElement) // do not add start on first run
        return ref.orderByChild('id').limitToFirst(itemsPerPage).startAt(startAtElement);
      return ref.orderByChild('id').limitToFirst(itemsPerPage);

    }).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => ({ key: action.key, ...<any>action.payload.val() }));
        }),
        take(1),
        map(articles => {
          return articles.filter((article:any)=>{
            return article.published; // only published articles
          });
        })
      )
      .toPromise()
      .then(snapshot => {

        // filter snapshot by category
        if (category) snapshot = snapshot.filter(article_data => article_data[category]);

        // if snapshot only has 1 element which includes the last element already shown then there is no more to load
        //     if (startAtElement && snapshot[0].id == startAtElement && snapshot.length == 1) 
        //         return {
        //             moreToLoad: false,
        //             data: <Article[]>[]
        //         };
        //     else {
        //console.log(snapshot);
        return this.settingsService.getSettings().then(settings => {
          return {
            moreToLoad: true,
            data: <Article[]>snapshot
              .map(article_data => new Article(article_data, settings) // returning articles
              )
          }
        });
        //     }
      })
      .catch(this.handleError);
  }

  /**
   * Fetches the Article by Key
   * @param   {string} key the key of the article
   *
   * @returns {Article} the article
   */
  getArticle(key: string): Promise<Article> {

    return this.afDatabase.object('/articles/' + key).valueChanges().pipe(
      take(1))
      .toPromise()
      .then(snapshot => {
        // force adding the key
        const article_data = { ...<any>snapshot, ...{ key: key } };
        return this.settingsService.getSettings().then(settings => {
          return new Article(article_data, settings)
        });
      })
      .catch(this.handleError);

  }

  /**
  * Fetches Articles that match an array of keys
  * @param   {string[]} article_keys article keys
  *
  * @returns {Promise<Article[]>} the articles
  */
  async getArticlesByList(article_keys: Array<string>): Promise<Article[]> {
    const get_array = article_keys.map(id => this.getArticle(id));
    return Promise.all(get_array);
  }

  /**
   * Fetches All Stories
   *
   * @returns {Promise<Story[]>} the stories
   */
  async getStories(): Promise<Story[]> {

    // execute query
    const stories = await this.afDatabase.list('/stories', function (ref) {
      return ref.orderByChild('id');
    }).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => ({ key: action.key, ...<any>action.payload.val() }));
        }),
        take(1),
        map(stories => {
          return stories.filter((story: any) => {
            return story.published;
          });
        })
      )
      .toPromise();

    console.log(stories);
    const settings = await this.settingsService.getSettings();
    return stories.map(story => new Story(story, settings));
  }

  /**
   * Fetches Story
   * @param key the story key
   *
   * @returns {Promise<Story>} the story
   */
  getStory(key: string): Promise<Story> {
    return this.afDatabase.object('/stories/' + key).valueChanges()
      .pipe(
        take(1)
      )
      .toPromise()
      .then(snapshot => {
        // force adding the key
        const story_data = { ...<any>snapshot, ...{ key: key } };
        return this.settingsService.getSettings().then(settings => {
          return new Story(story_data, settings)
        });
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  logArticleRead(translation_direction: string, article: Article): firebase.database.ThenableReference {
    // log the translation_direction
    let updates = {};
    updates[translation_direction] = true;
    this.afDatabase.object(`/analytics/users/translation_directions/${this.authService.user_id}/`).update(updates);

    return this.afDatabase.list(`/analytics/users/articleReads/${this.authService.user_id}/${translation_direction}/logs`).push({
      create_date_string: new Date().toString(),
      create_date: Date.now(),
      id: - Date.now(),
      article_title: article.title.main,
      article_key: article.key,
      content_main: article.title.main,
      content_help: article.title.help,
      translation_direction: translation_direction,
      type: "article"
    });
  }

  logSentenceTranslate(translation_direction: string, article: Article, sentence: LocalizedString): firebase.database.ThenableReference {
    return this.afDatabase.list(`/analytics/users/sentenceTranslations/${this.authService.user_id}/${translation_direction}/logs`).push({
      create_date_string: new Date().toString(),
      create_date: Date.now(),
      id: - Date.now(),
      article_title: article.title.main,
      article_key: article.key,
      content_main: sentence.main,
      content_help: sentence.help,
      translation_direction: translation_direction,
      type: "sentence"
    });
  }

  logWordTranslate(translation_direction: string, article: Article, sentence: LocalizedString, word_to_translate: string, word_translated: string): firebase.database.ThenableReference {
    return this.afDatabase.list(`/analytics/users/wordTranslations/${this.authService.user_id}/${translation_direction}/logs`).push({
      create_date_string: new Date().toString(),
      create_date: Date.now(),
      id: - Date.now(),
      article_title: article.title.main,
      article_key: article.key,
      sentence_main: sentence.main,
      sentence_help: sentence.help,
      sentence_key: sentence.key,
      content_main: word_to_translate,
      content_help: word_translated,
      translation_direction: translation_direction,
      type: "word"
    });
  }

  getAnalyticsCount(type: string, translation_direction: string): Observable<any> {
    return this.afDatabase.object(`/analytics/users/${type}/${this.authService.user_id}/${translation_direction}/count`).valueChanges();
  }

  getSentenceTranslationsCount(translation_direction: string): Observable<any> {
    return this.getAnalyticsCount("sentenceTranslations", translation_direction);
  }

  getWordTranslationsCount(translation_direction: string): Observable<any> {
    return this.getAnalyticsCount("wordTranslations", translation_direction);
  }

  getArticleReadsCount(translation_direction: string): Observable<any> {
    return this.getAnalyticsCount("articleReads", translation_direction);
  }

  getWeeklyLogs(type: string, translation_direction: string): Observable<any> {
    const lastweekdate = (d => (new Date(d.setDate(d.getDate() - 7))).getTime())(new Date);
    return this.afDatabase.list(`/analytics/users/${type}/${this.authService.user_id}/${translation_direction}/logs/`, ref => ref.orderByChild('id').endAt(-lastweekdate)).valueChanges();
  }

  getWeeklySentenceTranslationLogs(translation_direction: string): Observable<any> {
    return this.getWeeklyLogs("sentenceTranslations", translation_direction);
  }

  getWeeklyWordTranslationLogs(translation_direction: string): Observable<any> {
    return this.getWeeklyLogs("wordTranslations", translation_direction);
  }

  getWeeklyArticleReadLogs(translation_direction: string): Observable<any> {
    return this.getWeeklyLogs("articleReads", translation_direction);
  }

  getLogs(type: string, translation_direction: string): Observable<any> {
    return this.afDatabase.list(`/analytics/users/${type}/${this.authService.user_id}/${translation_direction}/logs/`, ref => ref.orderByChild('id')).valueChanges();
  }

  getSentenceTranslationLogs(translation_direction: string): Observable<any> {
    return this.getLogs("sentenceTranslations", translation_direction);
  }

  getWordTranslationLogs(translation_direction: string): Observable<any> {
    return this.getLogs("wordTranslations", translation_direction);
  }

  getArticleReadLogs(translation_direction: string): Observable<any> {
    return this.getLogs("articleReads", translation_direction);
  }

  getTranslationDirections(): Observable<any> {
    return this.afDatabase.object(`/analytics/users/translation_directions/${this.authService.user_id}/`).valueChanges();
  }

  getTopWords(locale_main: string): Observable<any> {
    return this.afDatabase.list(`/analytics/users/wordFrequency/${this.authService.user_id}/${locale_main}/`, ref => ref.orderByValue().limitToLast(100)).snapshotChanges().pipe(map(changes => {
      return changes.map(c => ({ word: c.payload.key, points: c.payload.val() })).reverse();
    }));
  }

  saveWordtoDictionnary(locale_main: string, word: string): Promise<void> {
    // log the translation_direction
    let updates = {};
    updates[word] = true;

    return this.afDatabase.object(`/dictionnaries/${this.authService.user_id}/${locale_main}`).update(updates);
  }
  deleteWordFromDicrionnary(locale_main: string, word: string): Promise<void> {
    // log the translation_direction
    let updates = {};
    updates[word] = null;

    return this.afDatabase.object(`/dictionnaries/${this.authService.user_id}/${locale_main}`).update(updates);
  }

  getUserDictionnary(locale_main: string) {
    return this.afDatabase.object(`/dictionnaries/${this.authService.user_id}/${locale_main}`).valueChanges();
  }

}
