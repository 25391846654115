import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Settings, SETTINGS_DEFAULTS } from '../../classes/settings';
import { LocalesService } from '../../services/locales.service/locales.service';
import { TranslateService } from '@ngx-translate/core';

import { Plugins } from '@capacitor/core';
const { Device } = Plugins;


import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, first, mergeMap } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import * as moment from 'moment';
// import 'moment/locale/es';
// import 'moment/locale/fr';
// import 'moment/locale/pt';
// import 'moment/locale/de';
// import 'moment/locale/tr';
// import 'moment/locale/ja';
// import 'moment/locale/zh-cn';
//import 'moment/locale/en';


@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private settings: Settings;
    private settingsStateSource = new BehaviorSubject<Settings>(new Settings({}));
    public settingsState$ = this.settingsStateSource.asObservable();

    constructor(
        public storage: Storage,
        public translateService: TranslateService,
        public afAuth: AngularFireAuth,
        private afDatabase: AngularFireDatabase,
        private localService: LocalesService,
    ) {
        console.log('Hello SettingsService Provider');
    }

    async getSettings(): Promise<Settings> {

        try {
            // check to see if already IN MEMORY
            if (this.settings) {
                this.settings = this.settings; // no change
            } else {
                // check if save in CLOUD
                let user = await this.afAuth.authState.pipe(
                    switchMap(user => {
                        // Logged in
                        if (user) {
                            console.log("logged in")
                            return this.afDatabase.object(`/users/${user.uid}`).valueChanges()
                                .pipe(
                                    map(userdata => {
                                        return { ...<any>userdata, auth: user };
                                    }));
                        } else {
                            // Logged out
                            return of(null);
                        }
                    }),
                    first()
                ).toPromise();
                if (user && user.settings) {
                    this.settings = new Settings(user.settings);

                } else {  // check to see if SAVED LOCALLY
                    let settings_json = await this.storage.get('settings')
                    let settings_obj = JSON.parse(settings_json);
                    if (!!settings_obj) {
                        this.settings = new Settings(settings_obj);


                    } else { // NEW SETTINGS
                        const deviceLanguageInfo = await Device.getLanguageCode();
                        var deviceLanguage = this.localService.getSuitableLanguage(deviceLanguageInfo.value);
                        this.settings = new Settings({
                            locale_help: deviceLanguage, // we only know the device language
                        });
                    }
                }

            }
        }
        catch (error) {
            console.log("Error new settings");
            console.log(error)
            this.settings = new Settings({});
            this.settingsStateSource.next(this.settings);
        }

        // update app translation
        this.translateService.use(this.settings.locale_help);

        // update moment
        let locale_main = this.settings.locale_main;
        if (locale_main == "zh") locale_main = "zh-cn"; // moment has issue with locale zh
        moment.updateLocale(locale_main, {});

        this.settingsStateSource.next(this.settings);
        return this.settings;

    }


    async setSettings(newSettings: Settings): Promise<void> {

        // updating IN MEMORY
        this.settings.locale_help = newSettings.locale_help;
        this.settings.locale_main = newSettings.locale_main;
        this.settings.font_size = newSettings.font_size;
        this.settings.translated_style = newSettings.translated_style;
        this.settingsStateSource.next(this.settings);

        // update app translation
        this.translateService.use(this.settings.locale_help);

        // update moment
        let locale_main = this.settings.locale_main;
        if (locale_main == "zh") locale_main = "zh-cn"; // moment has issue with locale zh
        moment.updateLocale(locale_main, {});

        // storing LOCALLY
        let settingsData = JSON.stringify(this.settings);
        await this.storage.set('settings', settingsData);

        // storing in CLOUD
        let user = await this.afAuth.authState.pipe(first()).toPromise();
        console.log(user);
        if (user && user.uid) {
            await this.afDatabase.object(`/users/${user.uid}`).update({ 
                settings: this.settings, 
                lastupdate_date: Date.now(), 
                lastupdate_date_string: new Date(),
            });
        }
    }

    clear() {
        this.settings = new Settings({});
        this.settingsStateSource.next(this.settings);
    }
}

