import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Import the Authentication provider.
import { AuthService } from '../../services/auth.service/auth.service';
import { DataService } from '../../services/data.service/data.service';

import { TranslateService, TranslateDirective, TranslatePipe } from '@ngx-translate/core';

import { Article } from '../../classes/article';
import { LocalizedString } from '../../classes/localized-string';

// analytics imports
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-translation-popover',
  templateUrl: './translation-popover.component.html',
  styleUrls: ['./translation-popover.component.scss']
})
export class TranslationPopoverComponent implements OnInit {

  // from props
  public word_to_translate: string;
  public sentence: LocalizedString;
  public article: Article;
  public locale_main: string;
  public locale_help: string;

  public word_translated: string;
  public word_tag: string;
  public word_backTranslation: Array<any> = [];

  public posts: any;
  public user;

  public TRANSLATION_STR: Observable<string>;
  public SAVE_WORD_OR_SYNONYM_STR: Observable<string>;

  constructor(
    public http: HttpClient,
    public platform: Platform,
    public AuthService: AuthService,
    private dataProvider: DataService,
    private segment: SegmentService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    console.log("In PopOver")
    console.log(`${this.word_to_translate}`);
    this.TRANSLATION_STR = this.translateService.get("TRANSLATION");
    this.SAVE_WORD_OR_SYNONYM_STR = this.translateService.get("SAVE_WORD_OR_SYNONYM");

    if (this.word_to_translate) {
      this.translate(this.locale_main, this.locale_help, this.word_to_translate).then(translation => {
        console.log(translation);
        this.word_translated = translation.translated;
        this.word_tag = translation.tag;
        this.word_backTranslation = translation.backTranslations.map(text => { return { text: text, saved: false } });

        // check if words saved to dictionnary
        this.dataProvider.getUserDictionnary(this.locale_main).subscribe(dict => {
          if (dict) this.word_backTranslation.map(word => {
            word.saved = !(!dict[word.text]);
            return word;
          });
        })

        // Keep log of users translations
        const translation_type = (this.sentence.getCurrentLocale() == this.sentence.getMainLanguage() ? "Help" : "Revert");
        const translation_direction = this.sentence.getCurrentLocale() + "=>" + (this.sentence.getCurrentLocale() == this.sentence.getMainLanguage() ? this.sentence.getHelpLanguage() : this.sentence.getMainLanguage());
        if (translation_type == "Help")
          this.dataProvider.logWordTranslate(translation_direction, this.article, this.sentence, this.word_to_translate, this.word_translated);

      });


      // analytics
      this.segment.track('Word Translated', {
        version: environment.version,
        platform: this.platform.platforms(),
        page: "Translation Popover Component",
        word: this.word_to_translate,
        locale_main: this.locale_main,
        locale_help: this.locale_help,
        locale_pair: this.locale_main + "/" + this.locale_help,
        locale_current: this.locale_main,
        locale_target: this.locale_help,
        translation_direction: this.locale_main + "=>" + this.locale_help,
      });

    }

  }

  private async translate(from, to, word) {
    let translation: any;
    translation = await this.microsoftTranslate(from, to, word);
    if (!translation) translation = await this.googleTranslate(from, to, word);
    return translation;
  }

  private googleTranslate(from, to, word): Promise<any> {
    // https://translation.googleapis.com/language/translate/v2
    let translate_url = 'https://translation.googleapis.com/language/translate/v2?'
      + 'key=AIzaSyBHMIoR7CZ1NQNa9-_vE1ihnlXAnexV1Tk'
      + '&format=text'
      + '&source=' + from
      + '&target=' + to
      + '&q=' + word
      + '&model=nmt';
    return this.http.get<any>(translate_url)
      .pipe(take(1))
      .toPromise().then(resp => {
        console.log(resp);
        if (resp.data.translations.length > 0) return {
          translated: resp.data.translations[0].translatedText,
          tag: null,
          backTranslations: [word]
        };
        return null;
      });
  }

  private microsoftTranslate(from, to, word): Promise<any> {
    // Azure Cognitive service test
    // if FROM (this.locale_main) = en then TO (this.locale_help) can be any language 
    // if FROM (this.locale_main) != en then TO (this.locale_help) can only be en
    if (this.locale_main == "en" || this.locale_help == "en") {
      let text = 'analizó,';
      let translation_url = "https://api.cognitive.microsofttranslator.com/dictionary/lookup?api-version=3.0"
        + '&from=' + this.locale_main
        + '&to=' + this.locale_help;

      let get_guid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': 'f156a01ca48740a692478d8413c98dc3',
          'X-ClientTraceId': get_guid()
        })
      };

      return this.http.post(translation_url, [{ 'Text': this.word_to_translate }], httpOptions)
        .pipe(take(1))
        .toPromise().then(resp => {
          console.log(resp);
          if (resp[0].translations.length > 0) return {
            translated: resp[0].translations[0].normalizedTarget,
            tag: resp[0].translations[0].posTag,
            backTranslations: resp[0].translations[0].backTranslations.map(t => t.normalizedText),
          };
          return null;
        });
    }
  }

  private normalizeText(word) {
    return word
      .replace(/"/g, '')
      .replace(/'/g, '')
      .replace(/\./g, '')
      .replace(/,/g, '')
      .replace(/!/g, '')
      .replace(/\?/g, '')
      .replace(/¿/g, '')
      .replace(/¡/g, '')
      .replace(/:/g, '')
      .replace(/%/g, '')
      .toLowerCase();
  }

  public toggleDictionnary(word) { // sync is handled by the subribe Observable
    let action = "";
    // delete
    if (word.saved) {
      this.dataProvider.deleteWordFromDicrionnary(this.locale_main, word.text);
      action = 'Word Unfavored';
    }
    //save
    else {
      this.dataProvider.saveWordtoDictionnary(this.locale_main, word.text);
      action = 'Word Favored';
    }

    // analytics
    this.segment.track(action, {
      version: environment.version,
      platform: this.platform.platforms(),
      page: "Translation Popover Component",
      word: word.text,
      locale_main: this.locale_main,
      locale_help: this.locale_help,
      locale_pair: this.locale_main + "/" + this.locale_help,
      locale_current: this.locale_main,
      locale_target: this.locale_help,
      translation_direction: this.locale_main + "=>" + this.locale_help,
    });

  }

}
