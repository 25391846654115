import { Injectable } from '@angular/core';
import { SETTINGS_DEFAULTS } from '../../classes/settings';

interface Locale {
  label: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocalesService {

  public SUPPORTED_LOCALES : Locale[] = [
    {label: 'English', value: 'en'},
    {label: 'Español', value: 'es'},
    {label: 'Français', value: 'fr'},
    {label: 'Deutsche', value: 'de'},
    {label: 'Português', value: 'pt'},
    {label: '中文', value: 'zh'},
    {label: '日本語', value: 'ja'},
    {label: '한국어', value: 'ko'},
    {label: 'Türk', value: 'tr'},
  ];

  constructor() { }

  getSupported(): Locale[] {
    return this.SUPPORTED_LOCALES;
  }

  getSuitableLanguage(language: string) {
    language = language.substring(0, 2).toLowerCase();
    return this.SUPPORTED_LOCALES.some(x => x.value == language) ? language : SETTINGS_DEFAULTS.locale_help;
  }

  splitTranslationDirection(translation_direction:string) : {locale_main: string, locale_help: string} {
    const t_split = translation_direction.split("=>")
    return {
      locale_main: t_split[0],
      locale_help: t_split[1]
    }

  }

}
