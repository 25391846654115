
import { Settings } from './settings';
import { LOCALES } from '../supported-locales';

export class LocalizedString {

    private locale_main: string;
    private locale_help: string;
    private current_locale: string;
    private _translated: boolean;
    private _words: any = {};
    public key: string;

    // suported locales
    locales = LOCALES; // need to find a way to fetch that data from elsewhere

    constructor(params: any, settings: Settings){
        if(params.key) this.key = params.key;
        // dynamically mapping supported locales
        this.locales.forEach(locale=>{
            if(params[locale.value]) {
                this[locale.value] = params[locale.value];
                this._words[locale.value] = this[locale.value].split(" ");
            }
        });
        // if(params.en) this.en = params.en;
        // if(params.fr) this.fr = params.fr;
        // if(params.es) this.es = params.es;

        // setting the help and main languages
        this.locale_help = settings.locale_help;
        this.locale_main = settings.locale_main;

        // identifying current locale
        this.current_locale = this.locale_main;
        this._translated = false;
    }

    get value(): string {
        return this[this.getCurrentLocale()];
    }

    get translated(): boolean{
        return this._translated;
    }

    get words(): string {
        return this._words[this.getCurrentLocale()];
    }  

    get main(): string {
        return this[this.getMainLanguage()];
    }

    get help(): string {
        return this[this.getHelpLanguage()];
    }

    getCurrentLocale(): string {
        return this.current_locale;
    }

    setCurrentLocale(locale: string): void {
        this.current_locale = locale;
    }

    toggleCurrentLocale(): void {
        this.setCurrentLocale(this._translated?this.locale_main:this.locale_help);
        this._translated = !this._translated; // flip bit
    }

    getMainLanguage(): string {
        return this.locale_main;
    }

    getHelpLanguage(): string {
        return this.locale_help;
    }
    
}