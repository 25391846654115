export const LOCALES = [
    {label: 'English', value: 'en'},
    {label: 'Español', value: 'es'},
    {label: 'Français', value: 'fr'},
    {label: 'Deutsche', value: 'de'},
    {label: 'Português', value: 'pt'},
    {label: '中文', value: 'zh'},
    {label: '日本語', value: 'ja'},
    {label: '한국어', value: 'ko'},
    {label: 'Türk', value: 'tr'},
  ];