export const SETTINGS_DEFAULTS = {
    locale_main: "es",
    locale_help: "en",
    font_size: "normal",
    translated_style: "bold",
    dark: false,
}

export class Settings {
    locale_main: string;
    locale_help: string;
    font_size: string;
    translated_style: string;
    dark: boolean;

    constructor(params: any){
        this.locale_main = params.locale_main || SETTINGS_DEFAULTS.locale_main;
        this.locale_help = params.locale_help || SETTINGS_DEFAULTS.locale_help;
        this.font_size = params.font_size || SETTINGS_DEFAULTS.font_size;
        this.translated_style = params.translated_style || SETTINGS_DEFAULTS.translated_style;
        this.dark = params.dark || SETTINGS_DEFAULTS.dark;
    }

    get translation_direction(){
        return this.locale_main + "=>" + this.locale_help;
    }
}