import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard/auth.guard';

const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' },

  { path: '', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [AuthGuard] },

  //{ path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [AuthGuard]  },
  // { path: 'home', loadChildren: './pages/articles/articles.module#ArticlesPageModule', canActivate: [AuthGuard]  },
  { path: 'articles', redirectTo: 'home/articles', pathMatch: 'full' },
  { path: 'article/:key', loadChildren: './pages/article/article.module#ArticlePageModule', canActivate: [AuthGuard] },
  { path: 'article', redirectTo: '/articles', pathMatch: 'full' },

  { path: 'stories', redirectTo: 'home/stories', pathMatch: 'full' },
  { path: 'story/:key', loadChildren: './pages/story/story.module#StoryPageModule', canActivate: [AuthGuard] },
  { path: 'story', redirectTo: 'home/stories', pathMatch: 'full' },

  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule', canActivate: [AuthGuard] },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule' },

  { path: 'learn', loadChildren: './pages/learn/learn.module#LearnPageModule' },

  { path: 'account', loadChildren: './pages/account/account.module#AccountPageModule' },
  { path: 'profile', redirectTo: 'account/profile', pathMatch: 'full' },
  { path: 'resetpassword', redirectTo: 'account/resetpassword', pathMatch: 'full' },
  { path: 'signin', redirectTo: 'account/signin', pathMatch: 'full' },
  { path: 'signup', redirectTo: 'account/signup', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
