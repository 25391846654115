import { LocalizedString } from './localized-string';
import { Settings } from './settings';

export class Article {
    id: number;
    title: LocalizedString;
    sentences: LocalizedString[];
    key: string;
    imgs: IImages;
    date: number;
    categories: string[] = [];
    published: boolean;
    next_key: string;
    prev_key: string;
    story_key: string;
    link: string;
    provider: string;

    constructor(params: any, settings: Settings) {
        if (params.key) this.key = <string>params.key;
        if (params.id) this.id = <number>params.id;
        if (params.title) this.title = new LocalizedString(params.title, settings);
        if (params.next_key) this.next_key = <string>params.next_key;
        if (params.prev_key) this.prev_key = <string>params.prev_key;
        if (params.story_key) this.story_key = <string>params.story_key;
        if (params.link) this.link = <string>params.link;
        if (params.provider) this.provider = <string>params.provider;
        if (params.sentences) {
            if (!(params.sentences instanceof Array))
                params.sentences = Object.keys(params.sentences).map(key => { // sending the undrlying array
                    params.sentences[key].key = key; // adding key to array
                    return params.sentences[key];
                });
            this.sentences = params.sentences.map(localized_data => new LocalizedString(localized_data, settings));
        }
        this.imgs = {
            img_150X150: (params.img_150X150?params.img_150X150:"assets/images/blank150X150.png"),
            img_650X300: (params.img_650X300?params.img_650X300:"assets/images/blank650X300.png")
        }
        
        if (params.title) this.date = <number>params.date;
        this.published = params.published;
        
        // building categories
        if(params.unitedstates) this.categories.push("unitedstates");
        if(params.world) this.categories.push("world");
        if(params.business) this.categories.push("business");
        if(params.entertainment) this.categories.push("entertainment");
        if(params.sports) this.categories.push("sports");
        if(params.technology) this.categories.push("technology");
        if(params.offbeat) this.categories.push("offbeat");
        if(params.french) this.categories.push("french");
        if(params.spanish) this.categories.push("spanish");
    }

}

interface IImages {
    img_150X150: string;
    img_650X300: string;
}